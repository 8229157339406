import {ReactElement} from 'react';
import {useTheme} from 'styled-components';
import {useMobile} from '@/lib/hooks/useMobile';

import {StyledPatterns} from './FeatureCardPatterns.styles';

type FeatureCardPaternsProps = {
  backgroundColour: string;
};

// Component
export default function FeatureCardPatterns(props: FeatureCardPaternsProps): ReactElement {
  const isMobile = useMobile();
  const { key } = useTheme();

  const src = `/FeatureCardPatterns/${key}/${props.backgroundColour === 'yellow' ? 'PatternFeature' : 'Pattern'}.svg`;
  return (
    key !== 'zsl' ?
    <StyledPatterns>
      {!isMobile && <img loading="lazy" alt="" className="pattern left" src={src} />}
      <img loading="lazy" alt="" className="pattern right" src={src} />
    </StyledPatterns>
    :
    <></>
  );
}
