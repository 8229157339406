import styled from 'styled-components';
import {mediaQueries} from '@/theme/lib';

export const StyledPatterns = styled('div')`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .pattern {
    display: block;
    position: absolute;
    width: 184px;
    height: 184px;
    ${mediaQueries.lg} {
      width: 381px;
      height: 381px;
    }
    &.left {
      bottom: 0;
      left: -10%;
    }
    &.right {
      right: -22%;
      top: 4.6875rem;
      ${mediaQueries.md} {
        right: -9%;
        top: -6rem;
      }
      ${mediaQueries.lg} {
        right: -9%;
        top: 2.0625rem;
      }
    }
  }
`;
